<template>
  <v-container id="dashboard" class="pb-0" fluid tag="section">
    <v-row dense>
      <v-col class="d-flex" cols="12">
        <v-breadcrumbs class="py-2" :items="breadcrumbs">
          <template v-slot:item="{ item }">
            <router-link :to="item.href" class="v-breadcrumbs__item">
              {{ item.text }}
            </router-link>
          </template>
        </v-breadcrumbs>
        <v-spacer />
        <v-btn-toggle v-model="selectedView" class="mb-1" dense mandatory>
          <v-btn icon>
            <v-icon>mdi-view-compact</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon>mdi-view-module</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon>mdi-view-list</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>
    <virtual-scroll
      ref="virtualCollection"
      :records="records"
      collection="marc_ath"
      :selected-view="selectedView"
      @reload="getRecords"
    />
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
import VirtualScroll from "../components/containers/VirtualScroll"
export default {
  name: "DashboardDashboard",
  components: { VirtualScroll },
  data() {
    return {
      totalResults: 1000000,
      itemsPerPage: 25,
      selectedView: 0,
      page: 1,
      bottom: false,
      category: "",
      records: []
    }
  },
  computed: {
    companyId() {
      return this.$store.getters.companyId
    },
    baseURL() {
      return axios.defaults.baseURL
    },
    breadcrumbs() {
      const arr = [
        {
          text: "Acasă",
          disabled: false,
          href: "/"
        }
      ]
      if (this.category != "") {
        arr.push({
          text: "Autorități",
          disabled: false,
          href: "/ath"
        })
      }
      return arr
    }
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        this.getRecords()
      }
    },
    $route() {
      //this.$log("route changed ", this.$route)
      this.category = ""
      if (this.$route.path.indexOf("cat-") > 0) {
        this.category = this.$route.path.substring(this.$route.path.indexOf("cat-") + 4, this.$route.path.length)
      }
      if (this.$route.name == "CategoriaAth" || this.$route.name == "Autoritati") {
        if (this.$refs.virtualCollection) {
          this.$refs.virtualCollection.resetCollection()
        }
        this.page = 1
        this.records = []
        this.getRecords()
        this.getRecords()
      }
    }
  },
  created() {
    if (this.$route.path.indexOf("cat-") > 0) {
      this.category = this.$route.path.substring(this.$route.path.indexOf("cat-") + 4, this.$route.path.length)
    }
    this.getRecords()
    this.getRecords()
  },
  methods: {
    openDetails(itm) {
      this.$log("openDetails ", itm)
      this.$router.push("/ath/" + itm._id)
    },
    getRecords() {
      if (this.itemsPerPage * (this.page - 1) <= this.totalResults) {
        let cat = ""
        if (this.category != "") {
          cat = '&where={"ctg":"' + this.category + '"}'
        }
        axios.get("ebib_marc_ath?max_results=" + this.itemsPerPage + "&page=" + this.page + cat).then(response => {
          this.totalResults = response.data._meta.total
          //this.records = []
          response.data._items.map(itm => {
            const rec = {}
            rec._id = itm._id
            for (const itms in itm) {
              if (itms != "fields") {
                rec[itms] = itm[itms]
              }
            }
            if (itm.ctg == "nume-personal") {
              rec.title = itm.fields["200"][0].a[0].val
              if (itm.fields["200"][0].b) {
                rec.title += ", " + itm.fields["200"][0].b[0].val
              }
              if (itm.fields["920"]) {
                rec.title += " (" + itm.fields["920"][0].a[0].val + ")"
              }
              if (itm.fields["200"][0].f) {
                rec.title += " (" + itm.fields["200"][0].f[0].val + ")"
              }
            } else {
              const flds = [200, 210, 215, 250, 270, 230, 220, 216, 260, 240, 245, 280, 235, 243, 217]
              flds.map(field => {
                if (itm.fields[field]) {
                  rec.title = itm.fields[field][0].a[0].val
                }
              })
            }
            for (const flds in itm.fields) {
              for (const fld in itm.fields[flds]) {
                for (const sub in itm.fields[flds][fld]) {
                  if (sub.length == 1) {
                    for (const subs in itm.fields[flds][fld][sub]) {
                      if (rec["fields." + flds + "." + sub + ".val"]) {
                        rec["fields." + flds + "." + sub + ".val"] += ", " + itm.fields[flds][fld][sub][subs].val
                      } else {
                        rec["fields." + flds + "." + sub + ".val"] = itm.fields[flds][fld][sub][subs].val
                      }
                    }
                  } else {
                    rec["fields." + flds + "." + sub] = itm.fields[flds][fld][sub]
                  }
                }
              }
            }
            //this.records.push(rec)
            this.records.push({
              data: rec
            })
          })
        })
        this.page++
      }
    }
  }
}
</script>
<style></style>
